import { useMemo } from 'react';
import { useRouter } from 'next/compat/router';
import { SliceZone } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

import { getPageComponents } from '@virginexperiencedays/cms/constants/slices';

import { ByPassBlock } from '@virginexperiencedays/components-v2/src/ByPassBlock';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { Stack } from '@virginexperiencedays/components-v2/src/layout/Stack';
import { slugToName } from '@virginexperiencedays/search/utils/category';
import { submit as onNewsletterSubmit } from '../../../utils/newsletter';

import getStructuredData from '../../SEO/StructuredData/miscPage';
import { DyContextPageType } from '@virginexperiencedays/dy';
import { GLOBAL_SETTINGS, PAGE_TYPES } from '../../tracking/gtm/consts';
import { getPageMetaData } from '../../SEO/utils/metadata';
import { pageView as trackFbPageView } from '../../tracking/fb/events';
import { useIsProductCardsV2 } from '../search/ProductCard';
import { bind as dangerouslyBindClickTracking } from '../search/tracking/clicks';
import { slice as trackSlice } from '../search/tracking/interactions';
import { useTracking } from '../../tracking/useTracking';
import { useValidB2bRoute } from '../../../utils/b2b/useValidB2bRoute';

import Breadcrumbs from '../../Breadcrumbs';
import DynamicYield from '../../DynamicYield/DynamicYield';
import Seo from '../../SEO/SEO';

import type { BasePageProps as PageProps } from '../types';

const mainContentId = 'custom-site-page-content';

const Page = ({ isDarkTheme, prismicPage, prismicSlices, slug, listOfLinks }: PageProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  // Since Custom Site Pages don't have Page Title field, will use Page Name which Prismic returns as slug
  const pageTitle = slugToName(slug);
  const pageType = prismicPage?.data?.page_type ?? null;
  const pathname = router.pathname;

  const seo = getPageMetaData({
    ...prismicPage.data,
    meta_title: prismicPage?.data?.meta_title || pageTitle,
    canonical: prismicPage?.data?.canonical || `${process.env.NEXT_PUBLIC_HOME_LINK}/${slug}`,
    metaTitleSuffixed: true,
  });

  const getFirstH1 = () => {
    if (typeof window !== 'undefined') {
      return document.getElementsByTagName('h1')?.[0]?.innerText;
    }
    return null;
  };

  const pageComponents = useMemo(() => getPageComponents(), []);

  // Structured Data
  // only get first hero image
  const image = prismicPage.data.slices?.find(({ slice_type }) => slice_type === 'hero_style01')
    ?.primary?.image;
  // get all faq groups and merge into one
  const faqs = prismicPage.data.slices
    ?.filter(({ slice_type }) => slice_type === 'faq_accordion_group')
    ?.flatMap(({ items }) =>
      items?.map((item) => ({
        question: item.heading,
        answer: prismicH.asHTML(item.content),
      }))
    );
  const structuredData = getStructuredData({
    page: prismicPage,
    title: getFirstH1() || seo.meta_title,
    description: seo.meta_description,
    image,
    faqs,
  });

  const isSlicesExisting = !!prismicSlices?.length;

  // stores slug in storage if B2B
  useValidB2bRoute(slug);

  useTracking({
    legacy: { pageName: pageTitle, pageType },
    gtm: { initial: [GLOBAL_SETTINGS, { ecommerce: null }] },
    fb: () => {
      trackFbPageView({
        name: pageTitle,
      });
    },
  });

  prismicSlices = dangerouslyBindClickTracking({
    slices: prismicSlices,
    slug,
    next: (url) => router.push(url),
    pageType,
    trackingPageType: PAGE_TYPES.CustomSite,
    isProductCardsV2,
  });

  return (
    <>
      <Seo {...seo} structuredData={structuredData} />
      <DynamicYield type={DyContextPageType.OTHER} data={pathname ?? []} />
      <Container>
        <Breadcrumbs asExternalLinks crumbs={[{ name: 'Home', path: '/' }, { name: pageTitle }]} />
      </Container>
      {isSlicesExisting && (
        <>
          <ByPassBlock mainContentId={mainContentId} />
          <Stack id={mainContentId}>
            <SliceZone
              slices={prismicSlices}
              components={pageComponents}
              context={{
                router,
                slug,
                lastSlug: slug,
                hide: false,
                pageTitle,
                pageType,
                isDarkTheme,
                isProductCardsV2,
                listOfLinks,
                trackSlice,
                onNewsletterSubmit,
                overridesSlugInfo: null,
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default Page;
